import React, { useEffect, useRef, useState } from "react";
import BottomSheet from "../../components/BottomSheet";
import { Link, useNavigate } from "react-router-dom";
import TopHeader from "../../components/TopHeader";
import { IoMdEyeOff } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import {
  useCheckEmailOtpMatchMutation,
  useGetValidateEmailQuery,
  useLoginMutation,
  useSendEmailOtpMutation,
} from "../../services/userApi";
import { loginValidate, Validate } from "../../components/validation";
import { Notification } from "../../components/ToastNotification";
import { savedLocalStorage } from "../../utils/function/localStorage";

const EmailLoginScreen = () => {
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  const [showPassword, setShowPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [errors, setErrors] = useState({});
  const [OTP, setOTP] = useState(["", "", "", ""]);
  const [otpCode, setOtpCode] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [countdown, setCountdown] = useState(40);
  const [user, setUser] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const [login, { data: loginData, isLoading, error }] = useLoginMutation();
  const { data: getValidateEmail } = useGetValidateEmailQuery(
    `${user?.username?.toLowerCase()}`
  );
  const [
    sendEmailOtp,
    {
      data: sendOtpData,
      error: sendOtpError,
      isLoading: sendOtpIsLoading,
      isSuccess: sendOtpIsSuccess,
      isError: sendOtpIsError,
    },
  ] = useSendEmailOtpMutation();
  const [
    checkEmailOtpMatch,
    {
      data: checkOtpMatchData,
      error: checkOtpMatchError,
      isError: checkOtpMatchIsError,
      isLoading: checkOtpMatchIsLoading,
    },
  ] = useCheckEmailOtpMatchMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (Object.keys(errors).length > 0) {
      Notification(errors.username || errors.password, "error");
    } else {
      const newObj = {
        username: user.username.toLowerCase(),
        password: user.password,
      };
      if (user.username && user.password) {
        await login(newObj);
      }
    }
  };

  const handleSendOTP = async (e) => {
    e.preventDefault();

    const data = {
      email: user?.username?.toLowerCase(),
    };

    await sendEmailOtp(data);
  };

  const handleValidateOtp = async (e) => {
    e.preventDefault();

    await checkEmailOtpMatch({
      email: user?.username?.toLowerCase(),
      otp: otpCode,
    });

    setOTP(["", "", "", ""]);
  };

  const handleChangeOtp = (value, index) => {
    const newOtp = [...OTP];
    newOtp[index] = value;
    setOTP(newOtp);
    if (value.length === 1 && index < 3) {
      inputRefs.current[index + 1].focus();
    }
    if (newOtp.every((item) => item !== "")) {
      setOtpCode(newOtp.join(""));
    }
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      if (OTP[index] === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else {
        const newOtp = [...OTP];
        newOtp[index] = "";
        setOTP(newOtp);
      }
    }
  };

  // handle form error
  useEffect(() => {
    setFormErrors(Validate({ email: user.username?.toLowerCase() }));
  }, [user.username]);
  // handle error
  useEffect(() => {
    setErrors(loginValidate(user));
  }, [user]);

  useEffect(() => {
    if (checkOtpMatchData?.status) {
      const emailAdditionalInfo = {
        email: user?.username?.toLowerCase(),
        otp: otpCode,
      };
      savedLocalStorage(
        "emailAdditionalInfo",
        JSON.stringify(emailAdditionalInfo)
      );
      navigate("/emailAdditionalInfo");
    }
  }, [checkOtpMatchData?.status]);

  useEffect(() => {
    if (loginData?.message) {
      Notification(loginData?.message, "success");
      localStorage.setItem("tp_Aa_uth_access", loginData?.data.token);
      navigate("/home");
      navigate(0);
    }
    if (error) {
      Notification(error?.data?.message, "error");
    }
  }, [loginData, error, navigate]);

  useEffect(() => {
    if (checkOtpMatchIsError) {
      Notification(
        checkOtpMatchError?.data?.message || "Something went wrong",
        "error"
      );
    }
  }, [checkOtpMatchIsError, checkOtpMatchError]);

  useEffect(() => {
    if (sendOtpIsSuccess) {
      setOtpSent(true);
      setCountdown(40);
      Notification(sendOtpData?.message || "OTP Sent Successfully", "success");
    }
    if (sendOtpIsError) {
      console.log(sendOtpError);
      Notification(
        sendOtpError?.data?.data?.message || "Something went wrong",
        "error"
      );
    }
  }, [sendOtpIsSuccess, sendOtpIsError, sendOtpData?.data, sendOtpError]);

  useEffect(() => {
    if (otpSent) {
      const timer = setInterval(() => {
        setCountdown((prevCount) => countdown > 0 && prevCount - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [otpSent, countdown]);

  return (
    <div className="tpu__emailLoginScreen__wrapper layoutContainer">
      {/* Header */}
      <TopHeader title="Login/Register" para="Ready to play and earn?" />
      {/* Bottom Sheet */}
      <BottomSheet height="85%">
        <div className="tpu__emailLoginScreen__bottomSheet__content">
          {!otpSent ? (
            <form onSubmit={handleSubmit}>
              <div className="emailLoginForm">
                <div
                  className="emailLoginField"
                  style={{ flexDirection: "column" }}
                >
                  <input
                    type="text"
                    name="username"
                    value={user.username}
                    className="loginInput"
                    onChange={handleChange}
                    placeholder="Username or Email"
                  />
                  {getValidateEmail?.data ? (
                    <>
                      {getValidateEmail && user?.username?.length > 0 && (
                        <p
                          style={{
                            color: getValidateEmail?.data ? "green" : "red",
                            fontSize: "12px",
                          }}
                        >
                          {getValidateEmail?.data
                            ? user?.username?.includes("@")
                              ? "Email exist in database"
                              : "Username exist in database"
                            : user?.username?.includes("@")
                            ? "Email not exist in database"
                            : "Username not exist in database"}
                        </p>
                      )}
                    </>
                  ) : (
                    <>
                      {user?.username?.includes("@") && formErrors?.email && (
                        <p
                          style={{
                            color: !formErrors?.email ? "green" : "red",
                            fontSize: "12px",
                          }}
                        >
                          {formErrors?.email}
                        </p>
                      )}
                    </>
                  )}
                </div>

                {getValidateEmail?.data && (
                  <>
                    <div className="emailLoginField">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={user.password}
                        className="loginInput"
                        onChange={handleChange}
                        placeholder="Password"
                      />
                      <span className="eyeIcon">
                        {showPassword ? (
                          <IoEye
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ) : (
                          <IoMdEyeOff
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )}
                      </span>
                    </div>
                    <Link to="/emailForgotPassword" className="forgotPassword">
                      Forgot Password?
                    </Link>
                  </>
                )}
              </div>

              <div className="submitButton">
                {getValidateEmail?.data ? (
                  <button
                    type="button"
                    className="loginButton"
                    disabled={!user.username || !user.password || isLoading}
                    onClick={handleSubmit}
                  >
                    <span>{isLoading ? "Loading..." : "LOGIN"}</span>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="loginButton"
                    disabled={!!getValidateEmail?.data || !!formErrors?.email}
                    onClick={handleSendOTP}
                  >
                    <span>{sendOtpIsLoading ? "Loading..." : "Send OTP"}</span>
                  </button>
                )}
              </div>
            </form>
          ) : (
            <form>
              <div
                className="mobileNumberField"
                style={{
                  justifyContent: "center",
                  background: "transparent",
                  border: "none",
                  display: "flex",
                }}
              >
                {OTP.map((data, index) => (
                  <input
                    key={index}
                    ref={(el) => (inputRefs.current[index] = el)}
                    type="text"
                    maxLength="1"
                    value={data}
                    onChange={(e) => handleChangeOtp(e.target.value, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    style={{
                      width: "40px",
                      height: "40px",
                      textAlign: "center",
                      margin: "5px",
                      fontSize: "22px",
                      outline: "none",
                      background: "#f4f7ff",
                      border: "1px solid lightgray",
                      borderRadius: "5px",
                    }}
                  />
                ))}
              </div>

              <div style={{ margin: "10px 0 15px" }}>
                <p
                  style={{
                    color: "gray",
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                >
                  Didn't receive OTP?{" "}
                  {countdown > 0 && (
                    <span>
                      Resend in <span>{countdown} Seconds</span>
                    </span>
                  )}
                </p>
              </div>

              {countdown > 0 ? (
                <div
                  style={{
                    marginVertical: 15,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    onClick={(e) => handleValidateOtp(e)}
                    style={{
                      backgroundColor: "#3871ff",
                      padding: "10px",
                      borderRadius: "5px",
                      width: "70%",
                      border: "none",
                    }}
                  >
                    <span
                      style={{
                        color: "#fff",
                        fontSize: "16PX",
                        textAlign: "center",
                      }}
                    >
                      {isLoading ? "Loading..." : "CONFIRM"}
                    </span>
                  </button>
                </div>
              ) : (
                <div
                  style={{
                    marginVertical: 15,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    style={{
                      backgroundColor: "#3871ff",
                      padding: "10px",
                      borderRadius: "5px",
                      width: "70%",
                      border: "none",
                    }}
                    disabled={countdown > 0}
                    onClick={(e) => handleSendOTP(e)}
                  >
                    <p
                      style={{
                        color: "#fff",
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    >
                      {sendOtpIsLoading ? "Loading..." : "Resend"}
                    </p>
                  </button>
                </div>
              )}
            </form>
          )}
        </div>
      </BottomSheet>
    </div>
  );
};

export default EmailLoginScreen;
