import React, { useState } from "react";
import { MdOutlineContentCopy } from "react-icons/md";
import "./PaymentMethod.css";

export default function PaymentMethod() {
  const [bankInformation] = useState({
    account_name: "3W Business Private Limited",
    account: "025405006735",
    ifsc_code: "ICIC0000254",
    bank_name: "ICICI Bank",
  });

  const [paytmInformation] = useState({
    account_no: "9415031640",
  });

  const [upiInformation] = useState({
    account_no: "3wbusinessprivatelimited.ibz@icici",
  });

  const [inputVal, setInputVal] = useState({
    trx: "TVe7rbRFd9xbKjFPGibq8pp9X8q8LMfAfc",
  });

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inputVal.trx);
    alert("Copied to clipboard");
  };

  return (
    <div className="payment-method-wrapper">
      <div className="payment-method-container">
        <div className="section-title">
          <h1>Payment Methods</h1>
        </div>

        <div className="payment-methods-list">
          <div className="payment-method">
            <h2>Banking Method</h2>
            <div className="method-content">
              <p>
                <strong>Account Name:</strong> {bankInformation.account_name}
              </p>
              <p>
                <strong>Account:</strong> {bankInformation.account}
              </p>
              <p>
                <strong>IFSC Code:</strong> {bankInformation.ifsc_code}
              </p>
              <p>
                <strong>Bank Name:</strong> {bankInformation.bank_name}
              </p>
            </div>
          </div>

          <div className="payment-method">
            <h2>Paytm Method</h2>
            <div className="method-content">
              <p>
                <strong>Paytm Account No:</strong> {paytmInformation.account_no}
              </p>
            </div>
          </div>

          <div className="payment-method">
            <h2>UPI Method</h2>
            <div className="method-content">
              <p>
                <strong>UPI Account No:</strong> {upiInformation.account_no}
              </p>
            </div>
          </div>

          <div className="payment-method">
            <h2>TRX Method</h2>
            <div className="method-content">
              <div className="qr-container">
                <img
                  src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1706607441/TaskPlanet/qr_ud7kli.png"
                  alt="QR Code"
                  className="qr-code"
                />
              </div>
              <div className="qr-address">
                <input
                  type="text"
                  value={inputVal.trx}
                  className="input-field"
                  readOnly
                />
                <button
                  onClick={copyToClipboard}
                  className="copy-button"
                  aria-label="Copy TRX ID"
                >
                  <MdOutlineContentCopy />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
