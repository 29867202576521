import { useNavigate } from "react-router-dom";
import Header from "../../../../components/Dashboard/Header";
import BottomMenu from "../../../../components/Dashboard/BottomMenu";
import {
  IoInformationCircleOutline,
  IoShieldCheckmarkOutline,
  IoShieldOutline,
  IoDocumentTextOutline,
} from "react-icons/io5";
import { AiOutlineContacts, AiOutlineRight } from "react-icons/ai";
import { FaChevronRight } from "react-icons/fa";

const About = () => {
  const navigate = useNavigate();

  return (
    <div className="tpu__myProfile__wrapper layoutContainer">
      <div className="layoutContainer">
        {/* Header */}
        <Header screenName="About" />
        {/* Body Content */}
        <div className="tpu__myProfile__section__area">
          <div className="about-container-wrapper">
            {/* About Us */}
            <div
              className="about-field-group"
              onClick={() => navigate("/home/about-us")}
            >
              <div className="about-field-content">
                <IoInformationCircleOutline className="about-field-icon" />
                <div>
                  <span className="about-field-label">About Us</span>
                </div>
              </div>
              <FaChevronRight className="about-arrow-icon" />
            </div>

            {/* Privacy Policy */}
            <div
              className="about-field-group"
              onClick={() => navigate("/home/privacy-policy")}
            >
              <div className="about-field-content">
                <IoShieldCheckmarkOutline className="about-field-icon" />
                <div>
                  <span className="about-field-label">Privacy Policy</span>
                </div>
              </div>
              <FaChevronRight className="about-arrow-icon" />
            </div>

            {/* Refund Policy */}
            <div
              className="about-field-group"
              onClick={() => navigate("/home/refund-policy")}
            >
              <div className="about-field-content">
                <IoShieldOutline className="about-field-icon" />
                <div>
                  <span className="about-field-label">Refund Policy</span>
                </div>
              </div>
              <FaChevronRight className="about-arrow-icon" />
            </div>

            {/* Terms and Conditions */}
            <div
              className="about-field-group"
              onClick={() => navigate("/home/terms-and-conditions")}
            >
              <div className="about-field-content">
                <IoDocumentTextOutline className="about-field-icon" />
                <div>
                  <span className="about-field-label">
                    Terms And Conditions
                  </span>
                </div>
              </div>
              <FaChevronRight className="about-arrow-icon" />
            </div>

            {/* Contact Us */}
            <div
              className="about-field-group"
              onClick={() => navigate("/home/contact-us")}
            >
              <div className="about-field-content">
                <AiOutlineContacts className="about-field-icon" />
                <div>
                  <span className="about-field-label">Contact Us</span>
                </div>
              </div>
              <FaChevronRight className="about-arrow-icon" />
            </div>

            {/* Feedback */}
            <div
              className="about-field-group"
              onClick={() => navigate("/home/feedback")}
            >
              <div className="about-field-content">
                <AiOutlineRight className="about-field-icon" />
                <div>
                  <span className="about-field-label">Feedback</span>
                </div>
              </div>
              <FaChevronRight className="about-arrow-icon" />
            </div>
          </div>
        </div>
        {/* Bottom Menu */}
        <BottomMenu />
      </div>
    </div>
  );
};

export default About;
