import React from "react";
import { MapPin, Mail, Building } from "lucide-react";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div className="contact-us-page">
      <div className="contact-us-container">
        <h1 className="contact-us-heading">Contact Us</h1>
        <div className="contact-us-content">
          <div className="contact-us-info">
            <Building className="contact-us-icon" />
            <div>
              <h2 className="contact-us-subheading">
                3W Business Private Limited
              </h2>
              <p className="contact-us-detail">CIN: U72900UP2022PTC163732</p>
            </div>
          </div>
          <div className="contact-us-info">
            <MapPin className="contact-us-icon" />
            <p className="contact-us-detail">
              G-014 Alpha-2, Greater Noida Gautam Buddha Nagar, Greater Noida,
              Uttar Pradesh, India, 201308
            </p>
          </div>
          <div className="contact-us-info">
            <Mail className="contact-us-icon" />
            <a
              href="mailto:support@taskplanet.org"
              className="contact-us-detail contact-us-email"
            >
              support@taskplanet.org
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
