import React, { useRef, useState, useEffect } from "react";
import AvatarDropdownMenu from "../avatarDropdownMenu";
import HeaderDropDown from "./HeaderDropDown/HeaderDropDown";
import {
  useClaimBtnQuery,
  useGetTotalPointsQuery,
  useGetWalletQuery,
  useMyProfileQuery,
  useGetNotificationsQuery,
} from "../../services/userApi";
import { IoGiftOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useClickOutside } from "./../../hooks/useClickOutside";
import { removeLocalStorage } from "./../../utils/function/localStorage";
import "./Header.css";
import NotificationModal from "../Modals/NotificationModal";
import { io } from "socket.io-client";
import { getLocalStorage } from "./../../utils/function/localStorage";
import { jwtDecode } from "jwt-decode";
import { env } from "../../env";
import { IoMdNotifications } from "react-icons/io";

const Header = ({ screenName, isGiftShow }) => {
  const [visible, setVisible] = useState(false);
  const [showFirstButton, setShowFirstButton] = useState(true);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [notificationList, setNotificationList] = useState([]);
  const [isBellIconActive, setIsBellIconActive] = useState(false);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const navigate = useNavigate();
  const { data: profile } = useMyProfileQuery();
  const bellIconRef = useRef(null);
  const { data: claimBtn } = useClaimBtnQuery();
  const { data: getTotalPoints, refetch: refetchTotalPoints } =
    useGetTotalPointsQuery();
  console.log(getTotalPoints);
  const { data: getWallet, refetch: refetchWallet } = useGetWalletQuery();
  const { data: notifications, refetch: refetchNotifications } =
    useGetNotificationsQuery("bell icon");
  // avater clckable menu
  const profileRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  useClickOutside(profileRef, () => setOpenMenu(false));
  // handle logout
  const handleLogout = () => {
    removeLocalStorage("tp_Aa_uth_access");
    window.location.reload();
  };

  const toggleNotificationModal = () => {
    const rect = bellIconRef.current.getBoundingClientRect();
    setModalPosition({ top: rect.bottom + 20, left: rect.left - 300 });

    setModalIsOpen(!modalIsOpen);
    setIsBellIconActive(!isBellIconActive); // Toggle the bell icon color
  };

  const closeNotificationModal = () => {
    setModalIsOpen(false);
    setIsBellIconActive(false); // Reset the bell icon color when modal closes
  };

  // Decode the JWT token from local storage
  const token = getLocalStorage("tp_Aa_uth_access");
  let decodedUsername = null;
  if (token) {
    const decodedToken = jwtDecode(token);
    decodedUsername = decodedToken.username;
  }

  const socket = io(env.BASE_URL, {
    transports: ["websocket"],
  });

  useEffect(() => {
    console.log("notification data ", notifications?.data);
    setNotificationList(notifications?.data);
  }, [notifications]);

  useEffect(() => {
    socket.on("newNotification", (notification) => {
      console.log("Received new notification:", notification);
      if (notification.username === decodedUsername) {
        setNotificationList((prevNotifications) => [
          ...prevNotifications,
          notification,
        ]);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [socket, decodedUsername]);

  useEffect(() => {
    refetchNotifications();
    refetchWallet();
    refetchTotalPoints();
  }, [refetchNotifications, refetchWallet, refetchTotalPoints]);

  return (
    <div className="header-container">
      <div className="header-main-view">
        <div>
          <h2 className="header-title">
            {screenName?.length > 13
              ? `${screenName?.slice(0, 11)}...`
              : screenName}
          </h2>
        </div>
        <div className="header-actions">
          <button
            className="points-button"
            onClick={() => navigate("/home/all-point-history")}
          >
            <img
              src="https://i.postimg.cc/1zKkRz5N/point.png"
              alt="points"
              className="points-icon"
            />
            <span className="points-text">
              {getTotalPoints?.currentPoint || 0}
            </span>
          </button>

          <button
            className="wallet-button"
            onClick={() => navigate("/home/wallet")}
          >
            ₹{getWallet?.data?.current_balance?.toFixed(2)}
          </button>

          {isGiftShow && (
            <button
              className="gift-button"
              onClick={() => navigate("/home/referral")}
            >
              {showFirstButton ? (
                <div className="gift-button-content">
                  <IoGiftOutline size={17} />
                </div>
              ) : (
                <span className="gift-button-text">₹1000</span>
              )}
            </button>
          )}

          <div
            ref={bellIconRef}
            className="tpu__dashboard__header__icon"
            onClick={toggleNotificationModal}
            style={{
              color: isBellIconActive ? "black" : "#8c8787",
              cursor: "pointer",
              marginTop: "10px",
              marginRight: "10px",
            }} // Conditionally change color
          >
            <IoMdNotifications size={24} />
          </div>
          {/* Notification Modal */}
          <NotificationModal
            isOpen={modalIsOpen}
            onClose={closeNotificationModal}
            notifications={notificationList}
            setNotification={setNotificationList}
            style={{
              top: `${modalPosition.top}px`,
              left: `${modalPosition.left}px`,
            }}
          />

          {profile?.data && (
            <div style={{ position: "relative" }}>
              <button
                className="header-profile-button"
                onClick={() => setOpenMenu(!openMenu)}
              >
                <img
                  src={
                    profile?.data[0]?.profile_pic ||
                    "https://res.cloudinary.com/green-valley-grocery/image/upload/v1667014541/tp-app-assests/icons/profile_nadfzb.png"
                  }
                  alt="profile"
                  className="header-avatar"
                />
              </button>
              {openMenu && (
                <HeaderDropDown
                  visible={openMenu}
                  setVisible={setOpenMenu}
                  navigation={navigate}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
