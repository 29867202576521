const usernameRegex = /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
export const Validate = (values) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const chars = /^[a-zA-Z\s]+$/;
  const lowercase = /[a-z]/;
  const uppercase = /[A-Z]/;
  const numbers = /[0-9]/;
  const symbols = /[!@#$%^&*()_+{:;"'.|/}]/;
  const passSymbols = /[!%^*()_+{:;"`~<>\'-\.=|/\\\\[\]}?]/;

  let errors = {};
  // // Sponsor Id validation
  // if (!values.sponsor_id) {
  //   errors.sponsor_id = 'Sponsor Id is required';
  // }
  // Username validation
  if (!values.username) {
    errors.username = "Username is required";
  } else if (values.username.length < 3) {
    errors.username = "Please write more than 3 charectars";
  }
  // else if (!numbers.test(values.username)) {
  //   errors.username = 'Please write atleast one number'
  // } else if (!isNaN(values.username)) {
  //   errors.username = 'Please write atleast one charectar'
  // }
  else if (symbols.test(values.username)) {
    errors.username = "Special charectar not allow";
  } else if (!usernameRegex.test(values.username)) {
    errors.username = "Username Not Validate";
  }
  // Fist name validation
  if (!values.first_name) {
    errors.first_name = "First name is required";
  } else if (!chars.test(values.first_name)) {
    errors.first_name = "Please write only charectar";
  } else if (values?.first_name.trim() === "") {
    errors.first_name = "Only space is not allowed.";
  }
  // Last name validation
  if (!values.last_name) {
    errors.last_name = "Last name is required";
  } else if (!chars.test(values.last_name)) {
    errors.last_name = "Please write only charectar";
  } else if (values?.last_name.trim() === "") {
    errors.last_name = "Only space is not allowed.";
  }
  // email validation
  if (!values.email) {
    errors.email = "Email is required";
  } else if (!re.test(values.email)) {
    errors.email = "Please Write A Valid Email Address";
  }

  // changes
  // limit 6>4
  // Password validation
  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 4) {
    errors.password = "Please Write More than 4 charectars";
  }
  // else if (!lowercase.test(values.password)) {
  //   errors.password = 'Please write atleast one lowercase charectar'
  // } else if (!uppercase.test(values.password)) {
  //   errors.password = 'Please write atleast one uppercase charectar'
  // } else if (!symbols.test(values.password)) {
  //   errors.password = 'Please write atleast one special charectar'
  // } else if (!numbers.test(values.password)) {
  //   errors.password = 'Please write atleast one number'
  // } else if (!isNaN(values.password)) {
  //   errors.password = 'Please write atleast one charectar'
  // }
  else if (values.password.includes(" ")) {
    errors.password = "Space are not allowed";
  } else if (passSymbols.test(values.password)) {
    errors.password = "Special characters @ # $ & are allowed only";
  }

  // confirm password validation
  if (!values.cpassword) {
    errors.cpassword = "Confirm Password is required";
  } else if (values.cpassword.length < 4) {
    errors.cpassword = "Please Write More than 4 charectars";
  }
  // else if (!lowercase.test(values.cpassword)) {
  //   errors.cpassword = 'Please write atleast one lowercase charectar'
  // } else if (!uppercase.test(values.cpassword)) {
  //   errors.cpassword = 'Please write atleast one uppercase charectar'
  // } else if (!symbols.test(values.cpassword)) {
  //   errors.cpassword = 'Please write atleast one special charectar'
  // } else if (!numbers.test(values.cpassword)) {
  //   errors.cpassword = 'Please write atleast one number'
  // } else if (!isNaN(values.cpassword)) {
  //   errors.cpassword = 'Please write atleast one charectar'
  // }
  else if (values.password.includes(" ")) {
    errors.password = "Space are not allowed";
  } else if (passSymbols.test(values.password)) {
    errors.password = "Special characters @ # $ & are allowed only";
  } else if (values.password !== values.cpassword) {
    errors.cpassword = "Password not match";
  }

  return errors;
};

export const loginValidate = (values) => {
  let errors = {};
  // email validation
  if (!values.username) {
    errors.username = "User ID is required";
  }
  // Password validation
  if (!values.password) {
    errors.password = "Password is required";
  }
  return errors;
};
