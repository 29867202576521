import React from "react";
import { useMyProfileQuery } from "../../../services/userApi";
// Import icons from react-icons
import { FiUser, FiLogOut, FiRocket } from "react-icons/fi";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { SlInfo } from "react-icons/sl";
import { removeLocalStorage } from "../../../utils/function/localStorage";
import "./HeaderDropDown.css";
import { useNavigate } from "react-router-dom";
import { IoRocketOutline } from "react-icons/io5";

const HeaderDropDown = ({ visible, setVisible }) => {
  const { data } = useMyProfileQuery();
  const navigate = useNavigate();
  const handleLogout = () => {
    removeLocalStorage("tp_Aa_uth_access");
    window.location.reload();
  };

  return (
    <div
      className="headerDropdown-container"
      style={{
        top: visible ? "55px" : "-800px",
      }}
    >
      <div className="headerDropdown-inner-container">
        <button
          className="headerDropdown-item"
          onClick={() => {
            navigate("/home/my-profile");
            setVisible(false);
          }}
        >
          <span>
            <FiUser /> My Profile
          </span>
          <span
            style={{
              backgroundColor: data?.data[0]?.is_premium_plus
                ? "#e0aa3e"
                : data?.data[0]?.is_premium
                ? "#82CD47"
                : "#2f68fd",
              padding: "3px 5px",
              borderRadius: "5px",
              fontSize: data?.data[0]?.is_premium_plus ? 11 : 12,
              color: "#fff",
            }}
          >
            {data?.data[0]?.is_premium_plus
              ? "Premium Plus"
              : data?.data[0]?.is_premium
              ? "Premium"
              : "Free"}
          </span>
        </button>

        {!data?.data[0]?.is_premium && !data?.data[0]?.is_premium_plus && (
          <button
            className="headerDropdown-item"
            onClick={() => {
              navigate("/home/premium");
              setVisible(false);
            }}
          >
            <span>
              <IoRocketOutline /> Activate Premium
            </span>
          </button>
        )}
        {!data?.data[0]?.is_premium_plus && (
          <button
            className="headerDropdown-item"
            onClick={() => {
              navigate("/home/premium");
              setVisible(false);
            }}
          >
            <span>
              <IoRocketOutline /> Activate Premium Plus
            </span>
          </button>
        )}

        <button
          className="headerDropdown-item"
          onClick={() => {
            navigate("/home/help-and-support");
            setVisible(false);
          }}
        >
          <span>
            <TfiHeadphoneAlt /> Help and Support
          </span>
        </button>

        <button
          className="headerDropdown-item"
          onClick={() => {
            navigate("/home/about");
            setVisible(false);
          }}
        >
          <span>
            <SlInfo /> About
          </span>
        </button>

        <button className="headerDropdown-item" onClick={handleLogout}>
          <span>
            <FiLogOut /> Sign Out
          </span>
        </button>
      </div>
    </div>
  );
};

export default HeaderDropDown;
