import toast, { Toaster } from "react-hot-toast";

export const Notification = (msg, type) => {
  const options = {
    duration: 1500,
    type: type,
    position: "top-center",
  };
  return toast(msg, options);
};
