import React from "react";
import "./RefundPolicy.css";

const RefundPolicy = () => {
  return (
    <div className="refund-policy-page">
      <div className="refund-policy-container">
        <header className="refund-policy-header">
          <h1 className="refund-policy-title">Cancellation & Refund Policy</h1>
          <p className="refund-policy-last-updated">
            Last updated on 23-04-2024 15:30:30
          </p>
        </header>

        <main className="refund-policy-content">
          <p className="refund-policy-intro">
            3W BUSINESS PRIVATE LIMITED believes in helping its customers as far
            as possible, and has therefore a liberal cancellation policy. Under
            this policy:
          </p>

          <ul className="refund-policy-list">
            <li className="refund-policy-list-item">
              Cancellations will be considered only if the request is made
              immediately after placing the order. However, the cancellation
              request may not be entertained if the orders have been
              communicated to the vendors/merchants and they have initiated the
              process of shipping them.
            </li>
            <li className="refund-policy-list-item">
              3W BUSINESS PRIVATE LIMITED does not accept cancellation requests
              for perishable items like flowers, eatables etc. However,
              refund/replacement can be made if the customer establishes that
              the quality of product delivered is not good.
            </li>
            <li className="refund-policy-list-item">
              In case of receipt of damaged or defective items please report the
              same to our Customer Service team. The request will, however, be
              entertained once the merchant has checked and determined the same
              at his own end. This should be reported within 10 days of receipt
              of the products. In case you feel that the product received is not
              as shown on the site or as per your expectations, you must bring
              it to the notice of our customer service within 10 days of
              receiving the product. The Customer Service Team after looking
              into your complaint will take an appropriate decision.
            </li>
            <li className="refund-policy-list-item">
              In case of complaints regarding products that come with a warranty
              from manufacturers, please refer the issue to them. In case of any
              Refunds approved by the 3W BUSINESS PRIVATE LIMITED, it'll take
              9-15 days for the refund to be processed to the end customer.
            </li>
          </ul>
        </main>
      </div>
    </div>
  );
};

export default RefundPolicy;
