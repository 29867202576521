import React from "react";
import { AiOutlineEnter } from "react-icons/ai";
import "./AboutUs.css";
import { useNavigate } from "react-router-dom";

const AboutUs = () => {
  const navigate = useNavigate();
  const joinNow = () => {
    navigate("/");
  };

  return (
    <div className="about-us-container">
      <section className="hero-section">
        <div className="hero-content">
          <h1 className="hero-title">A Platform For Online Earners</h1>
          <p className="hero-desc">
            Task Planet is an online money earning platform for individuals who
            are looking for small tasks and getting paid in return. All you need
            is a smartphone and internet connection and you are ready to go. Get
            yourself registered with us and enjoy the extra benefits of earning
            bucks.
          </p>
          <button className="join-now-btn" onClick={joinNow}>
            <AiOutlineEnter className="join-icon" />
            Join Now
          </button>
        </div>
        <div className="hero-image">
          <img
            src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1706606013/TaskPlanet/success_pcaymv.png"
            alt="Online earning illustration"
            className="hero-img"
          />
        </div>
      </section>

      <section className="about-section">
        <div className="about-content">
          <p className="about-subtitle">About Us</p>
          <h2 className="about-title">
            Best and trusted platform for earning online
          </h2>
          <p className="about-desc">
            Task planet is an online money earning website for individuals
            looking for small tasks and getting paid for them. All you need is a
            smartphone and an Internet connection and you are ready to go. Get
            registered with us and enjoy the extra benefits of earning bucks!
          </p>
        </div>
        <div className="about-image">
          <img
            src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1706605486/TaskPlanet/about_dcgelo.png"
            alt="Task Planet platform overview"
            className="about-img"
          />
        </div>
      </section>

      <section className="features-section">
        <div className="feature-box">
          <div className="feature-icon" style={{ backgroundColor: "#e9f2f4" }}>
            <img
              src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1706606726/TaskPlanet/mission_kqmfnu.png"
              alt="Mission"
            />
          </div>
          <h3 className="feature-title">Mission</h3>
          <p className="feature-desc">
            Our Mission is to organize and provide a platform to the people who
            are ready to help others. To create a society that understands the
            power of sharing, and make the world more open and connected.
          </p>
        </div>

        <div className="feature-box">
          <div className="feature-icon" style={{ backgroundColor: "#eae4ef" }}>
            <img
              src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1706606663/TaskPlanet/eye_qq6lrl.png"
              alt="Vision"
            />
          </div>
          <h3 className="feature-title">Vision</h3>
          <p className="feature-desc">
            Our vision is to help each person, no matter what challenges he or
            she faces, to make community to ordinary people, selflessly helping
            each other allows every individual to grow up, which in turn help
            their families also to grow up.
          </p>
        </div>

        <div className="feature-box">
          <div className="feature-icon" style={{ backgroundColor: "#ece7e0" }}>
            <img
              src="https://res.cloudinary.com/djfzcmvmr/image/upload/v1706606770/TaskPlanet/shield_iioaf6.png"
              alt="Reliability"
            />
          </div>
          <h3 className="feature-title">Reliability</h3>
          <p className="feature-desc">
            Here we can get health, wealth, prosperity time, happiness, and at
            last freedom from work. Through our platform we allow people to give
            donations to others and also to receive donations. By accepting
            donations, one can settle their life.
          </p>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
