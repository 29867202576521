import React, { useEffect, useState } from "react";
import Header from "../../../components/Dashboard/Header";
import BottomMenu from "../../../components/Dashboard/BottomMenu";
import { Notification } from "../../../components/ToastNotification";
import { FiHelpCircle } from "react-icons/fi";
import { LuMessageCircle, LuMail } from "react-icons/lu";
import { IoCallOutline } from "react-icons/io5";
import { MdKeyboardArrowRight } from "react-icons/md";

const HelpAndSupport = () => {
  return (
    <div className="kyc-wrapper layoutContainer">
      <div className="layoutContainer">
        {/* <Header screenName="KYC" /> */}
        <div className="help-support-header">
          <span className="help-support-header-text">Help & Support</span>
        </div>

        <div className="help-support-item">
          <span className="help-support-iconContainer">
            <FiHelpCircle />
          </span>
          <div className="help-support-textContainer">
            <div className="help-support-title">Help</div>
            <div className="help-support-subtitle">Popular FAQs</div>
          </div>
          <div className="help-support-arrow">
            <MdKeyboardArrowRight />
          </div>
        </div>

        <div className="help-support-item">
          <span className="help-support-iconContainer">
            <LuMessageCircle />
          </span>
          <div className="help-support-textContainer">
            <div className="help-support-title">Chat with Us</div>
            <div className="help-support-subtitle">Instant Support</div>
          </div>
          <div className="help-support-arrow">
            <MdKeyboardArrowRight />
          </div>
        </div>

        <div className="help-support-item">
          <span className="help-support-iconContainer">
            <IoCallOutline />
          </span>
          <div className="help-support-textContainer">
            <div className="help-support-title">Phone Support</div>
            <div className="help-support-subtitle">(9 AM - 6 PM)</div>
          </div>
          <div className="help-support-arrow">
            <MdKeyboardArrowRight />
          </div>
        </div>

        <div className="help-support-item">
          <span className="help-support-iconContainer">
            <LuMessageCircle />
          </span>
          <div className="help-support-textContainer">
            <div className="help-support-title">WhatsApp Support</div>
            <div className="help-support-subtitle">Fast Reply in 5 Minutes</div>
          </div>
          <div className="help-support-arrow">
            <MdKeyboardArrowRight />
          </div>
        </div>

        <div className="help-support-item">
          <span className="help-support-iconContainer">
            <LuMail />
          </span>
          <div className="help-support-textContainer">
            <div className="help-support-title">Email Support</div>
            <div className="help-support-subtitle">
              Response within 24 Hours
            </div>
          </div>
          <div className="help-support-arrow">
            <MdKeyboardArrowRight />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpAndSupport;
