import React from "react";
import AppContent from "./appContent";

const Dashboard = () => {
  return (
    <>
      <AppContent />
    </>
  );
};

export default Dashboard;
