import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { userApi } from "../services/userApi";
import { SocialMediaTask } from "../services/CreateSocialMediaTaskApi";
import loginPopupDisplayReducer from "./Slices/loginPopupDisplaySlice";
export const store = configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [SocialMediaTask.reducerPath]: SocialMediaTask.reducer,
    loginPopupDisplay: loginPopupDisplayReducer,
  },
  devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      userApi.middleware,
      SocialMediaTask.middleware,
    ]),
  /** here will be more reducer */
});

setupListeners(store.dispatch);
