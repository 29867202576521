import React from "react";

const BottomSheet = ({ children, height }) => {
  return (
    <div className="tpu__splashScreen__bottomSheet" style={{ height: height }}>
      {children}
    </div>
  );
};

export default BottomSheet;
